<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="isEdit">
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
      </h5>
      <div class="card-header-actions">
        <CButton color="dark" :to="{ name: 'Restaurant Types'}">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <CForm>
        <CRow>
          <CCol lg="6">
            <CInput
              label="Restaurant Type"
              :lazy="false"
              :value.sync="$v.form.type.$model"
              :isValid="checkIfValid('type')"
              placeholder="Type Name"
              invalidFeedback="This is a required field and must be at least 2 characters"
            />
          </CCol>

          <CCol lg="6">
            <CInput
              v-model="typeSlug"
              label="Slug"
              :lazy="false"
              :value.sync="$v.form.slug.$model"
              :isValid="checkIfValid('slug')"
              invalidFeedback="This is a required field"
              tabindex="-1"
            />
          </CCol>

          <CCol lg="6">
            <CInput
              label="FR"
              :lazy="false"
              :value.sync="$v.form.fr.$model"
              :isValid="checkIfValid('fr')"
              placeholder="Type Name in French"
              invalidFeedback="This is a required field and must be at least 2 characters"
            />
          </CCol>

          <CCol lg="6">
            <CInput
              v-model="typeSlugFr"
              label="Slug FR"
              :lazy="false"
              :value.sync="$v.form.slug_fr.$model"
              :isValid="checkIfValid('slug_fr')"
              invalidFeedback="This is a required field"
              tabindex="-1"
            />
          </CCol>

          <CCol lg="6">
            <CInput
              label="EN"
              :lazy="false"
              :value.sync="$v.form.en.$model"
              :isValid="checkIfValid('en')"
              placeholder="Type Name in English"
              invalidFeedback="This is a required field and must be at least 2 characters"
            />
          </CCol>

          <CCol lg="6">
            <CInput
              v-model="typeSlugEn"
              label="Slug EN"
              :lazy="false"
              :value.sync="$v.form.slug_en.$model"
              :isValid="checkIfValid('slug_en')"
              invalidFeedback="This is a required field"
              tabindex="-1"
            />
          </CCol>

          <CCol lg="6">
            <CMedia
              :aside-image-props="{
                src: this.previewTypeImage,
                shape: 'rounded',
              }"
            >
              <p class="mb-2 font-weight-bold">Type Image</p>
              <CInput
                :value.sync="$v.form.type_image.$model"
                type="hidden"
                class="mb-0"
              />
              <CInputFile
                :isValid="checkIfValid('type_image')"
                label = "Type Image"
                placeholder = "Chose an image..."
                custom
                @change = "onFileChanged"
              />
            </CMedia>

            <CLink v-if="previewTypeImage"
              class="small mt-2 text-danger"
              @click="() => { selectedTypeImage = previewTypeImage = null; form.type_image = ''; }"
            >
              <CIcon name="cil-trash" /> Remove Image
            </CLink>

          </CCol>
        </CRow>
      </CForm>
    </CCardBody>

    <CCardFooter>
      <CButton
        color="primary"
        :disabled="submitted"
        @click="submit"
      >
        Submit
      </CButton>
    </CCardFooter>

    <CElementCover :opacity="0.4" v-show="submitted"/>

  </CCard>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength } from "vuelidate/lib/validators"

export default {
  name: 'AddNewType',

  data() {
    return {
      form: {},
      isEdit: false,
      submitted: false,

      itemName: '',

      selectedTypeImage: undefined,
      previewTypeImage: undefined,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,
    }
  },

  created () {
    if(this.itemId) {
      this.form = this.getForm(this.itemId)
      this.isEdit = true
    }
    else
      this.form = this.getForm()
  },

  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    itemId() {
      return this.$route.params.id
    },

    typeSlug: {
      get: function () {
        return this.slug("type", "slug");
      },
      set: function (newValue) { }
    },
    typeSlugFr: {
      get: function () {
        return this.slug("fr", "slug_fr");
      },
      set: function (newValue) { }
    },
    typeSlugEn: {
      get: function () {
        return this.slug("en", "slug_en");
      },
      set: function (newValue) { }
    }
  },

  mixins: [validationMixin],

  validations: {
    form: {
      type: {
        required,
        minLength: minLength(2)
      },
      slug: {
        required,
      },
      fr: {
        required,
        minLength: minLength(2)
      },
      slug_fr: {
        required,
      },
      en: {
        required,
        minLength: minLength(2)
      },
      slug_en: {
        required,
      },
      type_image: {
        required: (fileName) => {
          if(!fileName)
            return true; // Image ekleme zorunlu değil

          var filename_arr = fileName.split(".");
          var file_ext = filename_arr[filename_arr.length - 1];

          return ["jpeg", "jpg", "png", "gif"].indexOf(file_ext) !== -1;
        }
      }
    }
  },

  methods: {
    slug: function (a, b)  {
      var $slug = this.$globalFunc.slugify(this.$v.form[a].$model)
      //Aşağıdaki if yapısını v-model ile slug oluşunca validation yapmadığı için kullandım.
      //Slug alanında veri olmasına rağmen $v.form[b].$model değeri boş dönüyordu.
      if (this.$v.form[a].$dirty) {
        this.$v.form[b].$model = $slug
        return $slug
      }
      // Bu satırı kullanmamın nedeni: Güncelleme işleminde form ilk görüntülendiğinde v-model -> slug uygulandığı için
      // veri tabanındaki kayıtlı slug alanları yerine yeni oluşturulan slug değerleri gösteriliyordu.
      // Bu durumda eğer veri tabanı üzerinda farklı bir slug değeri varsa görüntülenmiyordu.
      // Örn. (type)Türk Mutfağı -> (kayıtlı slug)turk-mutfak yerine (oluşturulan)turk-mutfagi görüntüleniyordu.
      return this.$v.form[b].$model;
    },

    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },

    onFileChanged(files, event) {
      this.selectedTypeImage = files[0];
      this.$v.form.type_image.$model = this.selectedTypeImage.name;

      // Yüklenen dosya geçerli kriterleri sağlıyor mu? (validations)
      if (this.checkIfValid("type_image"))
        this.previewTypeImage = URL.createObjectURL(this.selectedTypeImage);
      else {
        this.selectedTypeImage = undefined;
        this.previewTypeImage = undefined;
        this.$v.form.type_image.$model = '';
      }
    },

    async submit () {
      window.scrollTo(0,0);

      if (!this.isValid) {
        this.validate();
        return;
      }

      if (this.isEdit) {
        await this.update()
      } else {
        await this.store()
      }
    },

    async store () {
      this.submitted = true

      await Promise.all([
        this.uploadImage(),
      ]);

      await this.$axios.post(this.$backend.RESTAURANT_TYPES.STORE, this.form)
      .then((response)=>{
        this.successAlertMessage = {itemName: response.data.type, message: 'restaurant type added.'}
        this.dismissSuccessAlert = this.dismissSecs
        this.reset();
      })
      .catch((error)=>{
        this.errorAlertMessage = error.response.data
        this.showErrorAlert = true
      })
      .finally(() => {
        this.submitted = false
      });
    },

    async update () {
      this.submitted = true

      await Promise.all([
        this.uploadImage(),
      ]);

      await this.$axios.put(this.$backend.RESTAURANT_TYPES.UPDATE.replace("{id}", this.itemId), this.form)
        .then((response)=>{
          this.itemName = response.data.type
          this.successAlertMessage = {itemName: this.itemName, message: 'restaurant type updated.'}
          this.dismissSuccessAlert = this.dismissSecs
          this.showErrorAlert = false
        })
        .catch((error)=>{
          this.errorAlertMessage = error.response.data
          this.showErrorAlert = true
        })
        .finally(() => {
          this.submitted = false
        });
    },

    async uploadImage () {
      if(!this.selectedTypeImage) {
        return;
      }

      // Eğer resim güncellendiyse...
      const formData = new FormData()
      formData.append('type_image', this.selectedTypeImage, this.selectedTypeImage.name)
      formData.append('type_name', this.form.slug_en)

      await this.$axios.post(this.$backend.RESTAURANT_TYPES.SAVE_TYPE_IMAGE, formData)
        .then((response) => {
          //Api'den gelen resim yolunu hidden inputa ata (response varsa valid olacak.)
          this.form.type_image = response.data.data.type_image;
          this.selectedTypeImage = null;
        })
        .catch((error)=>{
          this.errorAlertMessage = error.response.data
          this.showErrorAlert = true
        })
    },

    validate () {
      //validate the form
      this.$v.$touch()
    },

    reset () {
      this.form = this.getForm()
      this.showErrorAlert = false
      this.selectedTypeImage = undefined
      this.previewTypeImage = undefined
      this.$v.$reset()
    },

    getForm (id = null) {
      var form = { type: "", slug: "", fr: "", slug_fr: "", en: "", slug_en: "", type_image: "" }

      if(id) {
        this.$axios.get(this.$backend.RESTAURANT_TYPES.SHOW.replace("{id}", id))
          .then(({data}) => {
            form.type = data.type;
            form.slug = data.slug;

            form.fr = data.fr;
            form.slug_fr = data.slug_fr;

            form.en = data.en;
            form.slug_en = data.slug_en;

            if(data.type_image_path) {
              const type_image = data.type_image_path;

              form.type_image = type_image;
              this.previewTypeImage = type_image;
            }

            this.itemName = data.type;
          })
          .catch((error) => {
            console.log("Error: ", error.response.data);
          })
      }

      return form;
    },
  }
}
</script>
